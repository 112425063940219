const copyable = () => {
    const clipboard = new ClipboardJS(".copyable");
    $('.copyable').tooltip();

    clipboard.on('success', function (e) {
        toastr.success('Email copiado para área de transferência', '', { "positionClass": "toast-bottom-left" })

        e.clearSelection();
    });

}

export { copyable }
