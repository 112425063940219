
const infiniteScroll = () => {
    // home infinfite scroll call
    const homeProfileList = document.querySelector('.profile-list')
    if (homeProfileList) { infiteScrollList(homeProfileList) }

    // infinite scroll
    function infiteScrollList(list) {
        if (window.innerWidth > 1100) {
            list.addEventListener('wheel', handleScroll, { passive: false });
        }

        function handleScroll(e) {
            e.preventDefault()
            e.stopPropagation()

            const listItems = list.querySelectorAll('li')
            const firstItem = list.querySelector('li')
            const lastItem = listItems[listItems.length - 1]

            if (e.deltaY < 0) {
                moveUp(firstItem, lastItem)
            } else {
                moveDown(firstItem)
            }
            return false;
        }

        function moveDown(item) {
            list.removeChild(item)
            list.appendChild(item)
        }

        function moveUp(firstItem, lastItem) {
            list.insertBefore(lastItem, firstItem)
        }
    }
}

export { infiniteScroll }