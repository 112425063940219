
const autoScroll = () => {
    // home auto scroll call
    const homeProfileList = document.querySelector('.profile-list')
    if (homeProfileList) {
        autoScrollList(homeProfileList)
        // manualArrowScroll(homeProfileList)
    }

    // auto scroll
    function autoScrollList(list) {
        const Delay = $(list).height() * 25;
        const DelayBack = $(list).height() * 25;

        function autoScroll() {
            $(list)
                .animate({ scrollTop: $(list).height() }, Delay, 'linear')
                .animate({ scrollTop: 0 }, DelayBack, autoScroll);
        }
        let intervalId = setInterval(autoScroll, 1000)

        if (window.innerWidth < 1100) {
            list.addEventListener('touchstart', () => {
                clearInterval(intervalId)
                $(list).stop(true, false)
            })
        } else {
            list.addEventListener('mouseover', () => {
                clearInterval(intervalId)
                $(list).stop(true, false)
            })
        }
    }

    // scroll with arrow
    // function manualArrowScroll(list) {
    //     const arrows = list.parentElement.querySelector('.arrows')
    //     const arrowUp = arrows.querySelector('.arrow-up')
    //     const arrowDown = arrows.querySelector('.arrow-down')

    //     list.addEventListener('mouseover', () => {
    //         arrows.classList.remove('d-none')
    //     })

    //     arrowUp.addEventListener('click', (e) => {
    //         const listItems = list.querySelectorAll('li')
    //         const firstItem = list.querySelector('li')
    //         const lastItem = listItems[listItems.length - 1]
    //         list.insertBefore(lastItem, firstItem)
    //     })

    //     arrowDown.addEventListener('click', (e) => {
    //         const firstItem = list.querySelector('li')
    //         list.removeChild(firstItem)
    //         list.appendChild(firstItem)
    //     })
    // }
}

export { autoScroll }