//= require_tree

require("jquery")
require("@nathanvda/cocoon")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

//= require bootstrap-table/bootstrap-table

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import "controllers"
import "bootstrap"

require("trix")
require("@rails/actiontext")
window.toastr = require('toastr')

import { copyable } from '../components/copyable'
import { infiniteScroll } from '../components/infinite_scroll'
import { autoScroll } from '../components/auto_scroll'
import { contrastSwitch } from '../components/contrast_switch'
import { localeSwitch } from '../components/locale_switch'

document.addEventListener('turbolinks:load', () => {
    copyable()
    infiniteScroll()
    autoScroll()
    contrastSwitch()
    localeSwitch()
})
