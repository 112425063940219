const contrastSwitch = () => {
    const input = document.getElementById('contrastSwitch')

    if (input) {
        input.addEventListener('change', () => {
            const container = document.querySelector('.page-container')
            const profileHeader = document.querySelector('.profile-header')
            const navbar = document.querySelector('.navbar-portal')

            if (input.checked) {
                container.classList.remove('red')
                navbar.classList.add('black')
                if (profileHeader) { profileHeader.classList.add('contrast') }
            } else {
                container.classList.add('red')
                navbar.classList.remove('black')
                if (profileHeader) { profileHeader.classList.remove('contrast') }
            }
        })
    }
}
export { contrastSwitch }
