
const localeSwitch = () => {
    const buttonPt = document.getElementById('locale-pt')
    const buttonEs = document.getElementById('locale-es')

    if (buttonPt && buttonEs) {
        const url = window.location['href'].replace('?locale=pt-BR', '').replace('?locale=es', '')


        buttonPt.addEventListener('click', () => {
            window.open(`${url}?locale=pt-BR`, '_self')
        })

        buttonEs.addEventListener('click', () => {
            window.open(`${url}?locale=es`, '_self')
        })
    }
}
export { localeSwitch }
